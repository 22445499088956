import {
  Box,
  Card,
  CardMedia,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core"
import clsx from "clsx"
import React from "react"
import { navigate } from "gatsby"
import LeafsCircleIcon from "../../icons/LeafsCircleIcon"
import Image from "../../simple/Image"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ImageCard from "../../ImageCard"
import { useTags } from "../../../utils/hooks"
import GatsbyImage from "../GatsbyImage"

const SquareIcon = props => (
  <Tooltip title={props.label}>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={1}
      bgcolor="#fff"
      //style={{ width: 24, height: 24 }}
    >
      <GatsbyImage
        filename={props.filename}
        alt={props.label}
        style={{ maxWidth: 24, maxHeight: 24 }}
      />
    </Box>
  </Tooltip>
)

const BrandCardListItem = ({ tags, image, name, variant, to }) => {
  const [getTagsInfo] = useTags()

  return (
    <ImageCard
      {...{ image, title: name, to, variant }}
      topLeftSlot={
        Array.isArray(tags) && (
          <Box display="flex" flexDirection="column">
            {tags.map((x, i, arr) => {
              const tag = getTagsInfo(x)

              return (
                <React.Fragment key={i + tag.frontmatter.label}>
                  <SquareIcon
                    filename={tag.frontmatter.icon}
                    label={tag.frontmatter.label}
                  />
                  {arr.length - 1 !== i && <Box mb={0.75} />}
                </React.Fragment>
              )
            })}
          </Box>
        )
      }
    />
  )
}

export default BrandCardListItem
