import { Box } from "@material-ui/core"
import React from "react"
import src from "../../assets/svg/icons/Group.svg"

const LeafsCircleIcon = ({
  color = "#000",
  bgcolor,
  height = 30,
  width = 30,
}) => (
  <img
    src={src}
    style={{ background: bgcolor, fill: color }}
    height={height}
    width={width}
  />
)

export default LeafsCircleIcon
