import React from "react"
import { Box, Card, CardMedia, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import { navigate } from "gatsby"
import Image from "./simple/Image"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import GatsbyImage from "./common/GatsbyImage"

const useStyles = makeStyles({
  root: {
    cursor: props => (!!props.to ? "pointer" : undefined),
  },
  image: {
    width: "100%",
  },
  imageSquare: {
    maxWidth: props => props.width,
    maxHeight: props => props.width,
  },
  title: { textTransform: "uppercase" },
})

const ImageCard = ({
  variant = "default",
  title,
  image,
  alt,
  to,
  topLeftSlot,
}) => {
  const handleClick = () => to && navigate(to)

  const [width, setWidth] = React.useState(0)
  const classes = useStyles({ width, to })
  const ref = React.useRef()

  React.useLayoutEffect(() => {
    const rect = ref.current.getBoundingClientRect()
    setWidth(rect.width)
  }, [])

  return (
    <Box
      position="relative"
      onClick={handleClick}
      ref={ref}
      className={classes.root}
    >
      <Box width="100%" minWidth="200px" />
      <GatsbyImage
        aspectRatio={variant === "square" ? 1 / 1 : undefined}
        //className={clsx(classes.image, {
        //    [classes.imageSquare]: variant === 'square'
        //})}
        filename={image}
        alt={title}
        objectFit="cover"
      />

      {topLeftSlot && (
        <Box
          display="flex"
          flexDirection="column"
          position="absolute"
          left={8}
          top={8}
        >
          {topLeftSlot}
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent={to ? "space-between" : "flex-start"}
        bgcolor="background.default"
        position="absolute"
        py={1}
        px={2}
        width="100%"
        textAlign="center"
        bottom={0}
        className={classes.title}
      >
        <Typography variant="h6">{title}</Typography>

        {to && <ChevronRightIcon />}
      </Box>
    </Box>
  )
}

export default ImageCard
