import { Box, Grid, makeStyles } from "@material-ui/core"
import React from "react"
import ExternalMasonry from "react-masonry-css"
import { useMobile } from "../utils/hooks"

export const GRID_GUTTER = 16

const useStyles = makeStyles(theme => ({
  masonryGrid: {
    display: "flex",
    marginLeft: `-${GRID_GUTTER}px` /* gutter size offset */,
    width: "100%",
    marginTop: GRID_GUTTER,
  },
  masonryGridColumn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexWrap: "wrap",
    paddingLeft: GRID_GUTTER /* gutter size */,
    backgroundClip: "padding-box",

    "& > div": {
      //background: 'grey',
      width: "100%",
      marginBottom: GRID_GUTTER,
    },
  },
}))

const MasonryGrid = ({ items }) => {
  const mobile = useMobile()
  const classes = useStyles()

  if (mobile){
    return <>
    <Grid container spacing={2}>
      <Grid item xs={12} />
      {items.map(el => <Grid item xs={12}>{el}</Grid>)}
    </Grid>
    </>
  }

  return (
    <ExternalMasonry
      breakpointCols={mobile ? 1 : 3}
      className={classes.masonryGrid}
      columnClassName={classes.masonryGridColumn}
    >
      {items}
    </ExternalMasonry>
  )
}

export default MasonryGrid
