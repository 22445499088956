import { Box, Grid } from "@material-ui/core"
import React from "react"
import MasonryGrid from "../../MasonryGrid"
import BrandCardListItem from "./BrandCardListItem"

const BrandCardList = ({ items, variant = "default" }) => {
  return (
    <Box width="100%">
      <Grid container spacing={2}>
        {items.map(({ node: x }, index) => (
          <Grid key={index} item xs={12} md={4}>
            <BrandCardListItem
              image={x.frontmatter.cardImage}
              name={x.frontmatter.name}
              tags={x.frontmatter.tags}
              to={x.fields.url}
              variant={variant}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default BrandCardList
